import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from 'src/app';
import { SENTRY_CONFIG } from 'src/config/sentry';

import PageLoader from './sharedComponents/PageLoader';

Sentry.init(SENTRY_CONFIG);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<PageLoader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
);
