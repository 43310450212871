import * as Sentry from '@sentry/react';
import {
  API_URL,
  SENTRY_ENV,
  SENTRY_KEY,
  SENTRY_ON_ERROR_SAMPLE_RATE,
  SENTRY_RELAYS_SESSION_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE
} from 'src/data/env';

import pkg from '../../package.json';

export const SENTRY_CONFIG = {
  dsn: SENTRY_KEY,
  environment: SENTRY_ENV,
  release: `${pkg.name}@${pkg.version}`,
  tracePropagationTargets: ['onboarding.trybrass.local', API_URL],
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: SENTRY_RELAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY_ON_ERROR_SAMPLE_RATE
};
